
import { Component, Vue } from "vue-property-decorator";

import SentencesTable from "@/components/Core/Table/SentencesTable.vue";

@Component({
  components: {
    SentencesTable,
  },
})
export default class Sentences extends Vue {}
