
import { Component, Inject, Vue } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import { ServerType } from "prometheus-synced-ui";
import { LITE_FEATURE, SentenceDetails, SentenceInfos } from "@/domain";

import TableUi from "@/components/Core/Table/TableUI.vue";

import { ISentenceService } from "@/services";

@Component({
  components: {
    TableUi,
  },
})
export default class SentencesTable extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get sentenceService() {
    return this.container.resolve<ISentenceService>(S.SENTENCE_SERVICE);
  }

  get createComponent() {
    return () => import("@/components/Core/Create/SentenceCreate.vue");
  }

  get updateComponent() {
    return () => import("@/components/Core/Update/SentenceUpdate.vue");
  }

  get headers() {
    return [
      {
        text: "Numéro",
        align: "center",
        sortable: true,
        value: "number",
      },
      {
        text: "Méthode",
        align: "center",
        sortable: true,
        value: "method",
      },
      {
        text: "Combo cible",
        align: "center",
        sortable: true,
        value: "target",
      },
      {
        text: "Phrase",
        align: "center",
        sortable: true,
        value: "sentenceValue",
      },
      {
        text: "Actions",
        align: "center",
        sortable: false,
        value: "actions",
        visibility: (serverType: ServerType) =>
          LITE_FEATURE.visibility(serverType),
      },
    ];
  }

  sentences: SentenceInfos[] = [];
  loading: boolean = false;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.sentences = await this.sentenceService.getMany();
    } finally {
      this.loading = false;
    }
  }

  create(sentence: SentenceDetails) {
    this.sentences.push(SentenceInfos.fromDetails(sentence));
  }

  update(sentence: SentenceDetails) {
    const index = _.findIndex(this.sentences, (s) => s.id == sentence.id);
    if (index != -1) {
      this.sentences.splice(index, 1, sentence);
    }
  }

  async remove(id: string) {
    try {
      this.loading = true;
      await this.sentenceService.remove(id);
      const index = _.findIndex(this.sentences, (s) => s.id == id);
      if (index != -1) {
        this.sentences.splice(index, 1);
      }
    } finally {
      this.loading = false;
    }
  }
}
